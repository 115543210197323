<template>
  <BaseProtoGroupPage :formField="formField" :cols="cols" :resetUrl="resetUrl" v-bind="permissionList">
    <template #menu="{row, sup_this}">
      <CopyProto v-if="permissionList.hasCopyProtoBtn" :data="row" @successHandler="sup_this.init"/>
    </template>
  </BaseProtoGroupPage>
</template>

<script>
import BaseProtoGroupPage from '../baseProtoGroupPage'
import CopyProto from './module/copyProto'
import { protoGroupListField as formField } from './field'
import { protoGroupListCols as cols } from './cols'
import { checkPermission } from '@/utils'

export default {
  components: {
    BaseProtoGroupPage,
    CopyProto
  },

  data() {
    return {
      formField,
      cols,
      resetUrl: '/externaladmin/productService/prototypeGroup/list'
    }
  },

  computed: {
    permissionList() {
      return {
        hasAddProtoGroup: checkPermission(['externaladmin:protoGroup:addGroup']),
        hasAssignDistributor: checkPermission(['externaladmin:protoGroup:assignProtoDistributor']),
        hasEditProtoGroup: checkPermission(['externaladmin:protoGroup:editGroup']),
        hasEnableProtoBtn: checkPermission(['externaladmin:protoGroup:disable']),
        hasDisableProtoBtn: checkPermission(['externaladmin:protoGroup:enable']),
        hasCopyProtoBtn: checkPermission(['externaladmin:protoGroup:copy'])
      }
    }
  }
}
</script>

<style>
</style>
